import React, { useEffect } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, useLocation, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./components/App";
import "./_styles/globals.scss";
import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";

const store = configureStore();
window.PAGE_LIMIT = 200;

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

render(
  <ReduxProvider store={store}>
    <Router>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </ReduxProvider>,
  document.getElementById("app")
);
