// Query
// export const SET_QUERY_ACTION = 'SET_QUERY_ACTION';

// Filters
// export const LOAD_FILTERS_ACTION = 'LOAD_FILTERS_ACTION';
export const LOAD_FILTER_ACTION = 'LOAD_FILTER_ACTION';
export const SET_FILTER_ACTION = 'SET_FILTER_ACTION';
// export const SET_YEAR_FILTER = 'SET_YEAR_FILTER';
// export const SET_PATHWAY_FILTER = 'SET_PATHWAY_FILTER';
// export const SET_LEVEL_ONE_FILTER = 'SET_LEVEL_ONE_FILTER';
// export const UNSET_LEVEL_ONE_FILTER = 'UNSET_LEVEL_ONE_FILTER';
// export const SET_LEVEL_TWO_FILTER = 'SET_LEVEL_TWO_FILTER';
// export const UNSET_LEVEL_TWO_FILTER = 'UNSET_LEVEL_TWO_FILTER';

// Scenarios
export const CREATE_SCENARIO_ACTION = 'CREATE_SCENARIO_ACTION';
export const LOAD_SCENARIOS_ACTION_SUCCESS = 'LOAD_SCENARIOS_ACTION_SUCCESS';
export const LOAD_SCENARIOS_ACTION_FAILURE = 'LOAD_SCENARIOS_ACTION_FAILURE';
export const LOAD_SCENARIO_BY_ID_ACTION_SUCCESS = 'LOAD_SCENARIO_BY_ID_ACTION_SUCCESS';
export const UPDATE_SCENARIO_ACTION = 'UPDATE_SCENARIO_ACTION';
export const DELETE_SCENARIO_ACTION = 'DELETE_SCENARIO_ACTION';

// Downloads
export const LOAD_DOWNLOADS_ACTION_SUCCESS = 'LOAD_DOWNLOADS_ACTION_SUCCESS';
export const LOAD_DOWNLOADS_ACTION_FAILURE = 'LOAD_DOWNLOADS_ACTION_FAILURE';

// Count
export const SET_COUNT_ACTION = 'SET_COUNT_ACTION';


// Api
export const BEGIN_API_CALL = 'BEGIN_API_CALL';